<div class="container">
    <mat-card class="topmatcard">
        <mat-card-header class="header-margin">
            <div class="header-content">
                <div class="header-content-left">
                    <mat-card-title>VinOPS API Tester</mat-card-title>
                    <!-- <button mat-icon-button (click)="getVersion()" [disabled]="!apiUrl || ! apiKey"> -->
                    <button mat-icon-button (click)="getVersion()" [disabled]="!apiUrl || !apiKey">
                        <mat-icon class="material-symbols-outlined">info</mat-icon>
                    </button>
                </div>
                <mat-card-title>{{umgebung}}</mat-card-title>
            </div>
        </mat-card-header>
    </mat-card>
    <div class="flex-container">
        <mat-card class="flex-item">
            <!-- <mat-card class="sticky-card">
            <mat-card-header class="header-margin">
                <div class="header-content">
                    <mat-card-title>VinOPS API Tester</mat-card-title>
                    <button mat-icon-button (click)="getVersion()" [disabled]="!apiUrl || ! apiKey">
                        <mat-icon class="material-symbols-outlined">info</mat-icon>
                    </button>
                </div>
            </mat-card-header>
        </mat-card> -->
            <mat-card-header class="header-margin">
                <mat-card-title>Request</mat-card-title>
                <!-- <button mat-raised-button color="primary" (click)="getVersion()">Info</button> -->
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="full-width">
                    <mat-label>API URL</mat-label>
                    <input matInput placeholder="API URL" [(ngModel)]="apiUrl" name="apiUrl">
                </mat-form-field>
            </mat-card-content>
            <mat-card-content>
                <mat-form-field class="full-width">
                    <mat-label>API Key</mat-label>
                    <input matInput placeholder="API Key" [(ngModel)]="apiKey" name="apiKey">
                </mat-form-field>
            </mat-card-content>
            <!-- mat-select for requestBodies -->
            <mat-card-content>
                <mat-form-field class="full-width">
                    <mat-label>Request Body Templates</mat-label>
                    <mat-select #requestBodySelect (selectionChange)="selectRequestBody($event)" (opened)="onSelectTemplatesClick()" [disabled]="!apiUrl || !apiKey">
                        <mat-option *ngIf="requestBodies.length === 0" disabled>Loading...</mat-option>
                        <mat-option *ngFor="let requestBody of requestBodies" [value]="requestBody.ID_RB">
                            {{ requestBody.subjectAction }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
            <!-- add a textarea to display currentDescription -->
            <mat-card-content class="header-margin" *ngIf="selectedRequestBody.ID_RB">
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>{{ selectedRequestBody.subjectAction }}</mat-panel-title>
                            <mat-panel-description>{{ selectedRequestBody.mandatory }}</mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="description-box">{{ selectedRequestBody.description }}</div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card-content>
            <mat-card-content>
                <mat-form-field class="full-width">
                    <mat-label>Request Body</mat-label>
                    <textarea matInput cdkTextareaAutosize placeholder="Request Body" [(ngModel)]="requestBody"
                        name="requestBody"></textarea>
                </mat-form-field>
            </mat-card-content>
            <mat-card-content>
                <!-- <div class="header-content"> -->
                <button mat-raised-button color="primary" class="action-button" (click)="sendRequestClick()"
                    [disabled]="!apiUrl || !apiKey || !requestBody">Send
                    Request</button>
                <!-- <div> -->
                <button mat-raised-button color="primary" class="action-button" (click)="copyRequestBody()">Copy Request
                    Body</button>
                <button mat-raised-button color="primary" class="action-button" (click)="clearRequestBody()">Clear
                    Request
                    Body</button>
            </mat-card-content>
            <!-- <pre> {{requestBodies | json}}</pre> -->
        </mat-card>

        <mat-card class="flex-item">
            <!-- <mat-card class="sticky-card">
            <mat-card-header class="header-margin">
                <div class="header-content">
                    <mat-card-title>{{umgebung}}</mat-card-title>
                </div>
            </mat-card-header>
        </mat-card> -->
            <mat-card-header>
                <mat-card-title>Response</mat-card-title>
            </mat-card-header>
            <div *ngIf="response">
                <mat-card-content>
                    Elapsed Time w/o rendering (ms): DB {{ elapsedTimeDB }} / Server & Traffic {{ elapsedTimeServer }} /
                    Total {{ elapsedTimeTotal }}
                </mat-card-content>
                <mat-card-content>
                    <pre class="wrapped-response">{{ response | json }}</pre>
                </mat-card-content>
                <!-- add a button to copy the response into the clipboard -->
                <mat-card-content>
                    <!-- <div class="button-container"> -->
                    <button mat-raised-button color="primary" class="action-button" (click)="copyResponse()">Copy
                        Response</button>
                    <button mat-raised-button color="primary" class="action-button" (click)="clearResponse()">Clear
                        Response</button>
                    <!-- </div> -->
                </mat-card-content>
            </div>
        </mat-card>
    </div>
</div>
<!-- Dialog Template -->
<ng-template #versionDialog let-data>
    <h2 mat-dialog-title>{{ data.message1 }}</h2>
    <mat-dialog-content>{{ data.message2 }}</mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>OK</button>
    </mat-dialog-actions>
</ng-template>
<!-- Confirm dialog for Buchung -->
<ng-template #confirmDialog let-data>
    <h1 mat-dialog-title>Confirmation Required</h1>
    <h2 mat-dialog-content>
        <p>{{ data.message }}</p>
    </h2>
    <div mat-dialog-actions>
        <button mat-button (click)="dialogRef.close(false)">Cancel</button>
        <button mat-button color="primary" (click)="dialogRef.close(true)">Confirm</button>
    </div>
</ng-template>