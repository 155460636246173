/*
Client to test the VinOPS API. Used for API Server development and testing for Website and internal dev.
*/
import { Component, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule, MatSelect } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';

import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';

interface RequestBodies {
  ID_RB: number;
  subjectAction: string;
  mandatory: string;
  description: string;
  rbody: object;
}

@Component({
  selector: 'app-apitest',
  standalone: true,
  templateUrl: './apitest.component.html',
  styleUrls: ['./apitest.component.scss'],
  imports: [
    FormsModule,
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatSelect,
    MatExpansionModule,
  ]
})
export class ApitestComponent {
  @ViewChild('requestBodySelect') requestBodySelect!: MatSelect;

  subjectActionBooking: string = 'Mieteinheit Buchung'; // 'rentalUnit booking'; // to be in sync with the DB

  apiUrl: string = environment.apiUrlDefault; 
  apiKey: string = ''; 
  requestBodies: RequestBodies[] = []; 

  selectedRequestBody!: RequestBodies; // = { ID_RB: 0, subjectAction: '', mandatory: '', description: '', rbody: {} };
  // requestDefault = environment.requestDefault; 
  requestBody: string = ''; // JSON.stringify(this.requestDefault, null, 2);
  response: any;
  // numberOfUnits: number = 0; // debug

  elapsedTimeDB: number = 0; // in milliseconds
  elapsedTimeServer: number = 0; // in milliseconds
  // elapsedTimeClient: number = 0; // in milliseconds
  elapsedTimeTotal: number = 0; // in milliseconds

  versionClient: string = '1.0'; // set client version here
  versionServer: string = 'x.x';
  versionApp: string = this.versionClient + ' - ' + this.versionServer;
  // versionApp: string = this.versionClient + ' - ' + this.versionServer + ' - ' + this.versionDB;
  @ViewChild('versionDialog') versionDialog!: TemplateRef<any>;
  @ViewChild('confirmDialog') confirmDialog!: TemplateRef<any>;
  dialogRef!: MatDialogRef<any>;

  umgebung: string = '';

  constructor(private http: HttpClient, private clipboard: Clipboard, private snackBar: MatSnackBar, private dialog: MatDialog, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    // console.log('API Tester initialized');
    if (environment.env === 'dev') { this.umgebung = 'Entwicklungsumgebung'; }
    else if (environment.env === 'test') { this.umgebung = 'Testumgebung'; }
    else if (environment.env === 'prod') { this.umgebung = 'Produktionsumgebung'; }
    else { this.umgebung = 'Unbekannte Umgebung'; }
    this.initSelectedRequestBody();
  }

  onSelectTemplatesClick() {
    if (this.requestBodies.length === 0) {
      // console.log('Select clicked and requestBodies array is empty. Fetching options...');
      this.getRequestBodies();
    }
  }

  selectRequestBody(event: any) {
    const selectedID = event.value;
    const selectedRequestBody = this.requestBodies.find(rb => rb.ID_RB === selectedID);
    if (selectedRequestBody) {
      this.selectedRequestBody = selectedRequestBody;
      this.requestBody = JSON.stringify(selectedRequestBody.rbody, null, 2);
      // reset the selected value
     // this.requestBodySelect.value = null;
      // console.log('this.requestBody:', selectedRequestBody.description, this.currentDescription);
    }
  }

  sendRequestClick() {
    // dialog to confirm the request in case of Buchung
    if (this.selectedRequestBody.subjectAction === this.subjectActionBooking) {
      const confirmText = 'This action will attempt to update the database with a new booking. Are you sure you want to proceed?';
      const confirmJson: any = { message: confirmText };
      this.dialogRef = this.dialog.open(this.confirmDialog, {
        data: confirmJson
      });
      this.dialogRef.afterClosed().subscribe(result => {
        // console.log(`Dialog result: ${result}`);
        if (result) {
          this.sendRequest();
        }
      }
      );
    } else {
      this.sendRequest();
    }
  }

  sendRequest() {
    const startTime = Date.now(); // Capture the start time
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    this.http.post(this.apiUrl, this.requestBody, { headers })
      .subscribe({
        next: res => {
          this.response = res;
        },
        error: err => {
          // Provide a fallback error message when the response is blocked by CORS
          const fehler = 'The request was likely blocked by the browser due to CORS policy violation or network /URL issues. Error Status: ' + err.status;
          const hinweis = 'Check the developer console for more details.';
          this.response = { System: { Fehler: fehler, Warnung: null, Hinweis: hinweis }, Daten: null };
        },
        complete: () => { 
          const endTime = Date.now(); // Capture the end time
          this.elapsedTimeDB = this.response.System.executionTimeDB || 0;
          this.elapsedTimeTotal = endTime - startTime; 
          this.elapsedTimeServer = this.elapsedTimeTotal - this.elapsedTimeDB; 
          // this.numberOfUnits = this.response.System.numberOfUnits || 0; 
          // this.cdr.detectChanges(); // Manually trigger change detection
          // const endTimeClient = Date.now(); // Capture the end time after rendering
          // this.elapsedTimeTotal = endTimeClient - startTime; // Calculate the elapsed time
          // this.elapsedTimeClient = this.elapsedTimeTotal - this.elapsedTimeDB - this.elapsedTimeServer; // Calculate the elapsed time 
        }
      });
  }

  clearRequestBody() {
    this.requestBody = '';
    this.initSelectedRequestBody();
    this.requestBodySelect.value = null;
  }

  clearResponse() {
    this.response = null;
  }

  copyResponse() {
    const responseText = JSON.stringify(this.response, null, 2);
    if (this.clipboard.copy(responseText)) {
      this.snackBar.open('Response copied to clipboard', 'Close', {
        duration: 2000,
      });
    } else {
      this.snackBar.open('Failed to copy response', 'Close', {
        duration: 2000,
      });
    }
  }

  copyRequestBody() {
    const requestBodyText = this.requestBody;
    if (this.clipboard.copy(requestBodyText)) {
      this.snackBar.open('Request body copied to clipboard', 'Close', {
        duration: 2000,
      });
    } else {
      this.snackBar.open('Failed to copy request body', 'Close', {
        duration: 2000,
      });
    }
  }

  getVersion(): void {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    const requestBody = JSON.stringify({ "Subjekt": "Version" }, null, 2);
    this.http.post(this.apiUrl, requestBody, { headers })
      .subscribe({
        next: res => {
          const version: any = res;
          this.versionServer = version.Version || '?.?';
          this.versionApp = this.versionClient + ' - ' + this.versionServer;
          const versionJson: any = { message1: 'VinOPS API Tester', message2: 'Version ' + this.versionApp };
          this.dialog.open(this.versionDialog, {
            data: versionJson
          });
        },
        error: err => {
          // Provide a fallback error message
          const fehler = 'Fehler bei der Abfrage der Server-Version. Error Status: ' + err.status;
          const hinweis = 'Check the developer console for more details.';
          this.response = { System: { Fehler: fehler, Warnung: null, Hinweis: hinweis }, Daten: null };
        }
      });
  }

  getRequestBodies() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-api-key': this.apiKey
    });
    const imp_json: any = JSON.stringify(
      {
        Subjekt: 'requestBodyTemplate',
        Aktion: 'requestBodies',
        Filter: ''
      });
    this.http.post(this.apiUrl, imp_json, { headers })
      .subscribe({
        next: (exp_json: any) => {
          // console.log('exp_json:', exp_json);
          if (exp_json.System) {
            if (exp_json.System && exp_json.System.Fehler) {
              this.response = exp_json.System;
              return;
            }
            if (exp_json.Daten && exp_json.Daten.requestBodies) {
              this.requestBodies = exp_json.Daten.requestBodies;
              // loop over requestBodies and replace // with / in description which was escaped in the JSON by MariaDB
              this.requestBodies.forEach(rb => {
                rb.description = rb.description.replace(/\\n/g, '\n');
              });
              // console.log('requestBodies:', this.requestBodies); // , this.requestBodies[0].rbody, typeof this.requestBodies[0].rbody);
            } else {
              this.requestBodies = [];
            }
          } else {
            this.response = { System: { Fehler: 'Something went badly wrong!', Warnung: null, Hinweis: null }, Daten: null };
          }
        },
        error: err => {
          // Provide a fallback error message when the response is blocked by CORS
          const fehler = 'The request was likely blocked by the browser due to CORS policy violation or network /URL issues. Error Status: ' + err.status;
          const hinweis = 'Check the developer console for more details.';
          this.response = { System: { Fehler: fehler, Warnung: null, Hinweis: hinweis }, Daten: null };
        },
        complete: () => {
          // this.requestBodySelect.open();
        }
      });
  }

  initSelectedRequestBody() {
    this.selectedRequestBody = { ID_RB: 0, subjectAction: '', mandatory: '', description: '', rbody: {} };
  }

}